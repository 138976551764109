<template>
    <div>
        <div class="wrapper" v-show="renderDone">
        <!-- header -->
        <!-- 大網展開menu: header-menu-sec 移除hidden -->
        <!-- 小網展開menu: wrapper 加class="header-nav-open" -->
        <header>
        <div class="pagesize">
            <div class="header-area">
          <h1><router-link to="/"><span class="zh">士林區區域電能管理系統</span><br /><span class="eng">SHILIN Community Energy Management
                System </span></router-link></h1>
            <p class="header-time">更新時間：{{updateTime}}</p>
            <a href="#" class="header-nav-btn"></a>
              <div class="header-nav">
                <a href="./login" class="btn-login" @click="LOGIN_OUT">登出</a>
                <ul class="header-menu-first">
                  <li><router-link to="/">能源地圖</router-link></li>
                  <li><router-link to="/electricity">發用電資訊</router-link></li>
                  <li><a href="#"  class="folder on" >需量反應</a>
                    <ul class="header-menu-sec">
                      <li><router-link to="./bidCalc">投標試算</router-link></li>
                      <li><router-link to="./bidRecord">得標及抑低分配發布</router-link></li>
                      <li><router-link to="./realTime">即時執行情形</router-link></li>
                      <li><router-link to="./history">歷史紀錄</router-link></li>
                      <li><router-link to="./group">群組成員資料</router-link></li>
                      <li><router-link to="./download">下載專區</router-link></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
        </div>
        </header>
        <!-- container -->
        <div class="container">
        <div class="pagesize">
            <ul class="breadcrumbs">
            <li><a href="#">需量反應</a></li>
            <li><span>投標試算</span></li>
            </ul>
            <div class="area bg-white margin02">
            <div class="padding03">
                <h2 class="boxtitle margin02">投標試算與建議</h2>
                <div class="history-area box-border box-round">
                <div class="flex-box flex-1 padding02 section-box bid-info">
                    <div class="section-inner section-2">
                    <div class="weather-box">
                        <h3 class="subtitle margin">明日天氣預測</h3>
                        <div class="weather-box-inner">
                        <p class="tempNum"><span>{{nextdayWeather[0]}}</span>&nbsp;℃</p>
                        <div class="imgbox">
                            <img :src="nextdayWeather[2]">
                        </div>
                        </div>
                    </div>
                    </div>
                    <div class="section-inner section-2">
                    <div class="flex-1">
                        <h3 class="subtitle f-left">{{bidnote.title}}{{bidnote.do}}倒數</h3>
                        <a class="f-right btn-small btn-goto bg-kahki " href="https://dbp.taipower.com.tw/TaiPowerDBP/Portal/Login" target="_blank">投標連結</a>
                        <div class="clr margin"></div>
                        <p class="texts-contdown">{{countdownTimer(getTimenow)}}</p>
                    </div>
                    </div>
                </div>
                <div class="reserve-area flex-box">
                    <!-- 投標價格 -->
                    <div class="box bid-box">
                    <div class="inner">
                        <h3 class="subtitle margin"><span>建議</span><span>投標價格</span></h3>
                        <p class="num" v-if="reserverate >= 10"><span>0~3</span>元</p>
                        <p class="num" v-if="reserverate > 5 && reserverate < 10"><span>3~5</span>元</p>
                        <p class="num" v-if="reserverate >= 3 && reserverate <= 5"><span>6~8</span>元</p>
                    </div>
                    </div>
                    <!-- 備轉容量 -->
                    <div class="box reserve-box">
                    <div class="reserve-inner">
                        <h3 class="subtitle color-white margin">明日預估<br />備轉容量率</h3>
                        <p class="reserve-status">供電充裕</p>
                    </div>
                    <div class="reserve-img">
                        <!-- <img src="images/index_weather03.png" alt=""> -->
                        <!-- bar -->
                        <div class="SemiCircleBar-box">
                        <div class="SemiCircleBar"></div>
                        <p class="min">0</p>
                        <p class="max">15</p>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
            <!-- area -->
            <div class="area bg-white margin02">
            <div class="padding03">
                <h2 class="boxtitle margin02">基準用電容量(CBL)試算</h2>
                <h3 v-if="errorMsg" style="color:red">{{errorMsg}}</h3>
                <!-- input -->
                <div class="graphCheck-area margin03">
                <div class="inner">
                    <div class="form-line margin0">
                    <div class="form-item">
                        <span>執行期間</span>
                        <div class="inputbtn w-l">
                          <date-picker style="width:auto" placeholder="起始日期" v-model="beginDate" valueType="format"></date-picker>
                        <!-- <input type="text" value="10">
                        <button class="date"></button> -->
                        </div>
                    </div>
                    <div class="form-item">
                        <select class="w-s" v-model="cblSelectBeginHour">
                          <option v-for = "i in 22" :key = i :value="('0'+ i).slice(-2)">{{i}}</option>
                        </select>
                        <select class="w-s" v-model="cblSelectMin">
                          <option>00</option>
                          <option>15</option>
                          <option>30</option>
                          <option>45</option>
                        </select>
                        <span>&nbsp;~&nbsp;</span>
                        <span class="font-size-xxl weight-n color-blue">{{parseInt(cblSelectBeginHour) + 2}}:</span>
                        <span class="font-size-xxl weight-n color-blue">{{cblSelectMin}}</span>
                        <!-- <select class="w-s" v-model="cblSelectEndHour">
                          <option v-for = "i in 24" :key = i :value="('0'+ i).slice(-2)">{{i}}</option> -->
                          <!-- <option>15</option> -->
                        <!-- </select> -->
                        <!-- <select class="w-s">
                          <option>{{cblSelectMin}}</option>
                        </select> -->
                    </div>
                    <div class="form-item">
                        <span class="font-size-xxl weight-n" style="margin:5px">共 </span>
                        <span class="font-size-xxl weight-n color-blue">2</span>
                        <span class="color-blue">小時</span>
                    </div>
                    </div>
                    <div class="mobile margin"></div>
                </div>
                <div class="btnbox">
                    <a class="btn-border" @click="queryCBLChart">查詢</a>
                </div>
                </div>
                <!--  -->
                <div class="box-border box-round padding02 margin02">
                <h3 class="boxtitle tC margin02">聯合型群組CBL試算 </h3>
                <ul class="point font-size-l">
                    <li>預估執行日期：{{cblText.date}}</li>
                    <li>基準時間：{{cblText.time}}</li>
                    <li>比較基準日期(前五日)：
                      <span class="color-blue-light" v-for="(i, idx) in cblText.exFiveDay" :key="idx">
                        <span v-if="idx < cblText.exFiveDay.length - 1">{{i}}</span>
                        <span v-if="idx < cblText.exFiveDay.length - 2">、</span>
                      </span>
                      <!-- <span class="color-blue-light">2020/7/13</span>、
                      <span class="color-blue-light">2020/7/14</span>、
                      <span class="color-blue-light">2020/7/15</span>、
                      <span class="color-blue-light">2020/7/16</span> -->
                    </li>
                </ul>
                </div>
                <p class="f-left color-blue-light weight-bold">基準用電容量(CBL)</p>
                <p class="f-right tR">
                <!-- <span class="colorLine color-blue-light">15分鐘最高需量(kW)</span> -->
                <!-- <span class="colorLine color-red margin-left">CBL(kW)</span> -->
                </p>
                <div class="clr margin"></div>
                <div class="imgbox full">
                    <Highcharts :options="groupCBLChart" ref="highchart" />
                <!-- <img src="./img/graph02.jpg"> -->
                </div>
            </div>
            </div>
            <!-- area -->
            <div class="area bg-white">
            <div class="padding03">
                <h2 class="boxtitle margin02">群組成員前五日15分鐘平均需量</h2>
                <h3 v-if="errorMsg" style="color:red">{{errorMsg}}</h3>
                <ul class="groupInfo-area">
                <li v-for="(i, idx) in siteCharArr" :key="idx">
                    <div class="groupInfo-title">
                    <p>{{siteArray[idx]}}</p>
                    </div>
                    <div class="groupInfo-inner">
                    <p class="f-left color-blue-light weight-bold">基準用電容量(CBL)</p>
                    <p class="f-right tR">
                    </p>
                    <div class="clr margin"></div>
                    <div class="imgbox full">
                        <Highcharts :options="i" ref="highchart" />
                    </div>
                    </div>
                </li>
                <!-- <li>
                    <div class="groupInfo-title">
                    <p>天文科學教育館</p>
                    </div>
                    <div class="groupInfo-inner">
                    <p class="f-left color-blue-light weight-bold">基準用電容量(CBL)</p>
                    <p class="f-right tR">
                    </p>
                    <div class="clr margin"></div>
                    <div class="imgbox full">
                    </div>
                    </div>
                </li> -->
                <!-- <li>
                    <div class="groupInfo-title">
                    <p>天文科學教育館</p>
                    </div>
                    <div class="groupInfo-inner">
                    <p class="f-left color-blue-light weight-bold">基準用電容量(CBL)</p>
                    <p class="f-right tR">
                        <span class="colorLine color-blue-light">15分鐘最高需量(kW)</span>
                        <span class="colorLine color-red margin-left">CBL(kW)</span>
                    </p>
                    <div class="clr margin"></div>
                    <div class="imgbox full">
                        <img src="./img/graph03.jpg">
                    </div>
                    </div>
                </li>
                <li>
                    <div class="groupInfo-title">
                    <p>士林分局</p>
                    </div>
                    <div class="groupInfo-inner">
                    <p class="f-left color-blue-light weight-bold">基準用電容量(CBL)</p>
                    <p class="f-right tR">
                        <span class="colorLine color-blue-light">15分鐘最高需量(kW)</span>
                        <span class="colorLine color-red margin-left">CBL(kW)</span>
                    </p>
                    <div class="clr margin"></div>
                    <div class="imgbox full">
                        <img src="./img/graph03.jpg">
                    </div>
                    </div>
                </li>
                <li>
                    <div class="groupInfo-title">
                    <p>士林運動中心</p>
                    </div>
                    <div class="groupInfo-inner">
                    <p class="f-left color-blue-light weight-bold">基準用電容量(CBL)</p>
                    <p class="f-right tR">
                        <span class="colorLine color-blue-light">15分鐘最高需量(kW)</span>
                        <span class="colorLine color-red margin-left">CBL(kW)</span>
                    </p>
                    <div class="clr margin"></div>
                    <div class="imgbox full">
                        <img src="./img/graph03.jpg">
                    </div>
                    </div>
                </li>
                <li>
                    <div class="groupInfo-title">
                    <p>士林市場</p>
                    </div>
                    <div class="groupInfo-inner">
                    <p class="f-left color-blue-light weight-bold">基準用電容量(CBL)</p>
                    <p class="f-right tR">
                        <span class="colorLine color-blue-light">15分鐘最高需量(kW)</span>
                        <span class="colorLine color-red margin-left">CBL(kW)</span>
                    </p>
                    <div class="clr margin"></div>
                    <div class="imgbox full">
                        <img src="./img/graph03.jpg">
                    </div>
                    </div>
                </li>
                <li>
                    <div class="groupInfo-title">
                    <p>士東市場</p>
                    </div>
                    <div class="groupInfo-inner">
                    <p class="f-left color-blue-light weight-bold">基準用電容量(CBL)</p>
                    <p class="f-right tR">
                        <span class="colorLine color-blue-light">15分鐘最高需量(kW)</span>
                        <span class="colorLine color-red margin-left">CBL(kW)</span>
                    </p>
                    <div class="clr margin"></div>
                    <div class="imgbox full">
                        <img src="./img/graph03.jpg">
                    </div>
                    </div>
                </li> -->
                </ul>
            </div>
            </div>
        </div>
        </div>
        <!--  footer -->
        <footer>
        <div class="pagesize">
            <div class="footer-info">
            <div class="info-box info-main">
                <div class="logo"></div>
                <div class="copyright">
                <p><a href="#">政府網站資料開放宣告</a>&nbsp;|&nbsp;<a href="#">隱私權及網站安全政策</a></p>
                <p>Copyright © All Rights Reserved</p>
                </div>
            </div>
            <div class="info-box info-sub">
                <p>執行單位：(02)2586-5000 # 325 陳小姐</p>
                <div class="logo02"></div>
            </div>
            </div>
        </div>
        </footer>
    </div>
    </div>
</template>

<style scoped>
    @import './css/inner.css';
</style>

<script>
import { mapMutations, mapGetters, mapState, mapActions } from 'vuex'
import { dbDataHandle, countDown } from '../store/function/index'
import progressbar from './js/jquery.progressbar'
import importJQ from './js/main'
import { DateTime } from 'luxon'
import cookies from 'vue-cookies'
import axios from 'axios'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
export default {
  components: { DatePicker },
  data () {
    return {
      errorMsg: '',
      updateTime: DateTime.local().toFormat('yyyy-MM-dd HH:mm:ss'),
      renderDone: false,
      query15Dmd: '',
      cblSelectBeginHour: DateTime.local().toFormat('HH') > 22 ? 22 : DateTime.local().toFormat('HH'),
      cblSelectEndHour: parseInt(DateTime.local().toFormat('HH')) + 1,
      cblSelectMin: '00',
      beginDate: DateTime.local().toFormat('yyyy-MM-dd'),
      siteCharArr: [],
      queryCBL: '',
      CBLTime: '',
      selectSite: ['SHILIN', 'shilinDistrict'],
      siteArray: ['士林區行政中心', '天文科學教育館', '士林市場', '士林分局', '士東市場', '士林運動中心'],
      siteCBLArr: ['SHILIN', 'TAIPEI_ASTRONOMICAL_MUSEUM', 'SHILIN_MARKET', 'SHILIN_PRECINCT_POLICE_STATION', 'SHIDONG_MARKET', 'SHILIN_SPORTS_CENTER'],
      cblText: {
        date: '',
        time: '',
        exFiveDay: []
      },
      bidnote: {
        title: '',
        do: ''
      },
      groupCBLChart: {
        chart: {
          backgroundColor: null
        },
        credits: {
          enabled: false
        },
        title: {
          text: ''
        },
        xAxis: {
          categories: ['2021/07/12', '2021/07/13', '2021/07/14', '2021/07/15', '2021/07/16']
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true
            },
            colorByPoint: true,
            colors: ['#a3dce3', '#a3dce3', '#a3dce3', '#a3dce3', '#a3dce3', '#897550']
          }
        },
        yAxis: [
          {
            title: {
              text: ''
            }
          },
          {
            title: {
              text: ''
            },
            opposite: true
          },
          {
            title: {
              text: ''
            },
            opposite: true
          }
        ],
        tooltip: {
          shared: true
        },
        series: [
          {
            type: 'column',
            yAxis: 0,
            name: '15分鐘平均需量(kW)',
            data: [5, 6, 4, 3, 2],
            color: '#a3dce3'
          },
          {
            type: 'line',
            yAxis: 0,
            name: 'CBL(kW)',
            data: [3, 3, 3, 3, 3],
            color: '#ef7e7e'
          }
        ],
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
                y: 0
              }
            }
          }]
        }
      }
    }
  },
  methods: {
    ...mapActions([
      'operatingReserveRate'
    ]),
    ...mapMutations([
      'COUNTING_TIMER',
      'LOGIN_OUT'
    ]),
    dbDataHandle (num, index) {
      return dbDataHandle(num, index)
    },
    countdownTimer () {
      if (this.CBLTime) {
        const cblDate = this.CBLTime.date
        const cblStartTime = this.CBLTime.timeStart
        const cblString = cblDate + ' ' + cblStartTime
        const today = DateTime.local().toFormat('yyyy-MM-dd HH:mm')
        const time = cblStartTime.slice(0, 2)
        const minute = cblStartTime.slice(-2)
        const year = cblDate.slice(0, 4)
        const month = cblDate.slice(5, 7)
        const date = cblDate.slice(-2)
        if (today > cblString) {
          this.bidnote.title = '需量反應'
          this.bidnote.do = '投標'
          return countDown(11)
        } else {
          this.bidnote.title = '已得標'
          this.bidnote.do = '執行'
          return countDown(time, minute, year, month, date)
        }
      } else {
        this.bidnote.title = '需量反應'
        this.bidnote.do = '投標'
        return countDown(11)
      }
    },
    async reserverateProgress () {
      const date = DateTime.local().toFormat('yyyy-MM-dd')
      const data = await axios.get(`https://tpcems.tier.org.tw/api/tpcems/open/home/operating-reserve-by-date?date=${date}`)
      var barValue = data.data.operatingReserveRate // 設定數值顯示文字
      var bar = new progressbar.SemiCircle('.SemiCircleBar', {
        strokeWidth: 6,
        color: '#c0ff37',
        trailColor: '#fff',
        trailWidth: 6,
        easing: 'easeInOut',
        duration: 1000,
        svgStyle: null,
        text: {
          value: ''
          // alignToBottom: true
        },
        step: function (state, circle) {
          circle.setText(barValue)
        }
      })
      // bar.animate(0.3) /* b設定bar參數  0~1.0 */
      bar.animate(barValue / 15)
    },
    async queryCBLChart () {
      this.groupCBLChart.xAxis.categories = []
      this.groupCBLChart.series[0].data = []
      this.groupCBLChart.series[1].data = []
      this.cblText.exFiveDay = []

      await this.getqueryCBL()

      if (this.queryCBL.success) {
        const differentData = []
        const highchartData = []
        this.queryCBL = this.queryCBL.data.reverse()

        this.siteCBLArr.forEach(x => {
          const arr = this.queryCBL.filter(y => y.ems === x)
          differentData.push(arr)
        })

        const totalArr = Array.from({ length: differentData[0].length }, (v) => 0)

        differentData.forEach(x => {
          const chart = JSON.parse(JSON.stringify(this.groupCBLChart))
          for (let i = 0; i < x.length; i++) {
            chart.xAxis.categories.push(x[i].date)
            chart.series[0].data.push(this.dbDataHandle(x[i].meanDemand))
            totalArr[i] = this.dbDataHandle(totalArr[i] + this.dbDataHandle(x[i].meanDemand))
          }
          let CBL = 0
          for (let i = 0; i < x.length - 1; i++) {
            CBL = CBL + x[i].meanDemand
          }
          for (let i = 0; i < x.length; i++) {
            chart.series[1].data.push(this.dbDataHandle(CBL / (x.length - 1)))
          }
          highchartData.push(chart)
        })

        this.siteCharArr = []
        this.siteCharArr = highchartData

        // 聯合型群組CBL試算
        this.cblText.exFiveDay = JSON.parse(JSON.stringify(highchartData[0].xAxis.categories))
        this.groupCBLChart.xAxis.categories = JSON.parse(JSON.stringify(highchartData[0].xAxis.categories))
        this.groupCBLChart.series[0].data = totalArr
        const totalCBl = this.dbDataHandle((totalArr.reduce((x, y) => x + y) - totalArr[totalArr.length - 1]) / (totalArr.length - 1))
        this.groupCBLChart.series[1].data = Array.from({ length: totalArr.length }, (v) => totalCBl)
      }
    },
    async getqueryCBL () {
      this.cblText.date = this.beginDate
      this.cblText.time = `${this.cblSelectBeginHour}:${this.cblSelectMin} ~ ${('0' + (Number(this.cblSelectBeginHour) + 2)).slice(-2)}:${this.cblSelectMin}`
      const token = cookies.get('Authorization')
      const queryCBL = await axios.post('/api/cems/queryCBL',
        {
          queryDate: this.beginDate,
          timeStart: `${this.cblSelectBeginHour}:${this.cblSelectMin}`,
          timeEnd: `${('0' + (Number(this.cblSelectBeginHour) + 2)).slice(-2)}:${this.cblSelectMin}`
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (queryCBL.data.success) {
        this.queryCBL = queryCBL.data
      } else {
        this.errorMsg = queryCBL.data.msg
      }
    },
    async getCBLTime () {
      const token = cookies.get('Authorization')
      const CBLTime = await axios.post('/api/cems/winBid15Dmd',
        {
        },
        {
          headers: { Authorization: `bearer ${token}` }
        })
      if (CBLTime.data.success) {
        this.CBLTime = CBLTime.data.dataDetail
      }
    }
  },
  async mounted () {
    importJQ()
    this.getCBLTime()
    this.reserverateProgress()
    this.operatingReserveRate()
    await this.queryCBLChart()
    this.renderDone = true
  },
  computed: {
    ...mapGetters([
      'getTimenow'
    ]),
    ...mapState([
      'login',
      'navbar',
      'weather',
      'pop',
      'reserverate',
      'currentWeather',
      'nextdayWeather'
    ])
  }
}
</script>
